/*@import '~antd/dist/antd.css';*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mainDivRouter {
  flex-grow: 1;
  margin-top: 90px;
  margin-left: 30px;
  margin-right: 30px;
}
.loadingDiv {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
}

.loginGrid {
  justify-content: center;
  align-items: center;
  height: 65vh;
}
.loginGridDiv {
  color: #000;
  padding: 20px;
  margin: 40px;
  display: inline-block;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
}